@use "uswds-core" as *;
@use "usa-table" as *;

/* default styling for tables in Markdown */
table {
  @extend .usa-table;
}

blockquote {
  margin-left: 0px;
  padding-left: units(1);
  border-left: units(2px) solid color('base-light');
}

/* Code highlighting */
/* ----------------- */
code,
kbd,
pre,
samp {
  @include u-font('mono','sm');
  @include u-line-height('mono',5);
}

code {
  @include u-padding-x('1px');
  @include u-padding-y(0);
  @include u-margin(0);
  @include u-margin-right('2px');
  @include u-bg('base-lightest');
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.75);
}

h1 {
  code,
  kbd,
  pre,
  samp {
    @include h1;
    @include u-font-family('mono');
  }
}

h2 {
  code,
  kbd,
  pre,
  samp {
    @include h2;
    @include u-font-family('mono');
  }
}

h3 {
  code,
  kbd,
  pre,
  samp {
    @include h3;
    @include u-font-family('mono');
  }
}

h4 {
  code,
  kbd,
  pre,
  samp {
    @include h4;
    @include u-font-family('mono');
  }
}

h5 {
  code,
  kbd,
  pre,
  samp {
    @include h5;
    @include u-font-family('mono');
  }
}

h6 {
  code,
  kbd,
  pre,
  samp {
    @include h6;
    @include u-font-family('mono');
  }
}

/*
li code, p code, td > code, td > a > code, div.callout code {
  @include u-padding-x('1px');
  @include u-padding-y(0);
  @include u-margin(0);
  @include u-margin-right('2px');
  background-color: color('base-lightest');
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.75);
}
*/

/* fix code highlighting in alerts */
@include at-media("desktop") {
  .usa-alert .usa-alert__body {
    @include u-padding-x(5)
  }
}
@include at-media("desktop") {
  .usa-alert--no-icon .usa-alert__body {
    @include u-padding-x(2)
  }
}
.usa-alert {
  &--success {
    p code {
      background-color: color('success-light');
    }
  }
  &--warning {
    p code {
      background-color: color('warning-light');
    }
  }
  &--error {
    p code {
      background-color: color('error-light');
    }
  }
  &--info {
    p code {
      background-color: color('info-light');
    }
  }
  &--emergency {
    p code {
      background-color: color('emergency');
    }
  }
}

.usa-prose > p + *, p + * {
  margin-top: 0.75em;
}

.usa-prose > * + h1, .usa-prose > * + h2, .usa-prose > * + h3, .usa-prose > * + h4, .usa-prose > * + h5, .usa-prose > * + h6, * + h1, * + h2, * + h3, * + h4, * + h5, * + h6 {
  margin-top: 0.75em;
}

/* highlight broken links */
a.broken {
  background: #ff0;
  border: 2px solid #f00;
  padding: 0.1em 0.2em;
}
