@use "uswds-core" as *;
@use "../variables" as *;

body{
  text-rendering: optimizeLegibility;
}

.site-sidenav + .main-content {
  @include at-media("desktop") {
    width: calc(100% - #{units($width-nav-sidebar)});
  }
}

.main-content {
  @include u-pin("right");
  @include u-pin("bottom");
  @include add-responsive-site-margins;
  margin-top: units(2); // align horizontal edge; starting: not scrolled
  margin-bottom: units(6);
  padding-left: units(2);
  padding-right: units(2);
  max-width: units($theme-grid-container-max-width);

  display: inline-block;
  width: initial;
  position: relative;

  header {
    > nav.usa-breadcrumb {
      padding-top: 0; // align horizontal edge
      padding-bottom: 0;

      ol.usa-breadcrumb__list {
        padding-top: 0; // align horizontal edge
        @include typeset(
          $theme-breadcrumb-font-family,
          $theme-breadcrumb-font-size,
          3
        );
      }
    }

    > h1 {
      margin-top: 0;
      margin-bottom: 0;
    }

    & + p {
      margin-top: units(1.5);
    }
  }

  > h2 {
    @include typeset("lang", "xl", 2);
    color: color($site-ink);
    margin-top: units(4);
  
    & + p {
      margin-top: units(1.5);
    }
  }

  > h3 {
    @include typeset("lang", "md", 3);

    & + p {
      margin-top: units(1);
    }
  }

  > h4 {
    @include typeset("lang", "2xs", 3);
    margin-top: units(3);

    & + p,
    & + ol,
    & + ul {
      margin-top: units(1);
    }
  }

  > h5 {
    @include typeset("lang", "2xs", 3);
    @include u-text($site-ink, "bold");
    margin-top: units(2);

    & + p,
    & + ol,
    & + ul {
      border-top: units(1px) solid color("base-lighter");
      padding-top: units(1);
      margin-top: units(0.5);
    }
  }

  h1,h2,h3,h4,h5,h6 {
    a {
      text-decoration: inherit;
      color:inherit;
      &:visited {
        color:inherit;
      }
    }
  }
}

h4 code, h6 code {
  text-transform: none !important; // undo default uppercase
}

img[usemap] {
  border: none;
  height: auto;
  max-width: 100%;
  width: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
}

div.mermaid svg {
  width: 100%;
}
