@use "uswds-core" as *;

@mixin generate-sidenav-nesting($list-selector,$left-padding-width, $depth, $i: 1){
  @if $i <= $depth {
    ul.#{$list-selector} {
      @include typeset($theme-sidenav-font-family, "2xs", 3);
      a {
        padding-left: #{$left-padding-width * $i}rem;
      }
      @include generate-sidenav-nesting($list-selector,$left-padding-width, $depth, $i + 1);
    }
  }
}

.site-sidenav {
  display: none;

  @include at-media("desktop") {
    display: block;
    float: left;
    margin-bottom: units(6);
    margin-top: units(2);
    padding-right: 0;
    padding-left: units(4);
    width: units("card-lg");
    top: 4em;
  }

  .usa-sidenav__item {
    border: none;
    a {
      border-top: units(1px) solid color("base-lighter");
    }
  }
  .usa-current {
    border-left: units("05") solid #005ea2;
    margin-left: calc(-1 * units("05"));

    &::after {
      display: none;
    }
  }
}

ul.usa-sidenav {
  @include typeset($theme-sidenav-font-family, "2xs", 3);
  @include generate-sidenav-nesting('usa-sidenav__sublist',0.90, 6);

  > li.usa-sidenav__item.usa-subnav__top-level > a {
    @include u-text('bold');
    background-color: color('base-lightest');
    padding-left: 0.90rem;
    border-radius: units("05") units("05") 0 0;
  }
}
