@use "uswds-core" as *;

div.callout {
//    @include u-border(1px);
    @include u-padding-x(1.5);
    @include u-padding-y(0.5);
    @include u-bg('primary-lighter');
    margin-top: units(1);
    border: units('1px') solid color('base-light');
    box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.5);
//    -webkit-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.5);
//    -moz-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.5);
}


