@use "uswds-core" as *;
@use "../variables" as *;

$site-navbar-height: 10;
$site-secondary-nav-input-height: 38px;

.sticky {
  @include at-media("desktop") {
    @include u-z(200);
    position: sticky;
    top: 0;
  }
}

.site-header {
  @include typeset("lang");
  margin-top: units(1);

  @include at-media("desktop") {
    border-bottom: none;
    background-color: color($theme-body-background-color);
  }

  &__navbar {
    border-bottom: none !important;
    display: flex !important;

    @include at-media("desktop") {
      max-width: units($theme-grid-container-max-width);
      height: units($site-navbar-height) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.usa-header--extended {
    .site-logo {
      @include at-media("desktop") {
        margin-top: units(2px);
        margin-bottom: 0 !important;
      }

      a {
    //      color: color($site-ink);
      }

      &__text {
        $logo-size-mobile: 3;
        $logo-size-desktop: 3;
        @include u-font("lang", "xs");
//        line-height: units(5);

        @include at-media("desktop") {
          @include typeset("lang", "lg", 1);
//          line-height: units(9);
          display: inherit;
          letter-spacing: ls(-1);
        }
      }
    }

    .usa-menu-btn {
      font-size: fs("lang", "3xs");
    }
  }

    // Search and CTA buttons
  .site-nav-secondary {
    display: none;
    @include at-media("desktop") {
      display: flex;
      position: relative;
      bottom: initial;
      right: initial;
      margin-left: auto;
  //    align-items: center;
  //    display: flex;
  //    flex-direction: row;
  //    height: units($site-navbar-height);
      margin-top: 0;
  //    bottom: px-to-rem(46px);
    }

    .usa-button {
      display: block;
    }

    .site-search__button,
    .site-button-github {
      &:hover {
        background-color: color("primary-vivid");
      }
    }
  }
}

.site-title {
  &--short {
    @include at-media("mobile-lg") {
      display: none;
    }
  }

  &--long {
    display: none;

    @include at-media("mobile-lg") {
      display: initial;
    }
  }
}

//
// Navigation
// ==========================================================================
.site-nav {
  @include typeset("lang");
  background-color: color("white");
  border-bottom: units($site-border-width) solid
    color($site-base-transparent-light);
  padding-left: units(2);
  max-width: none !important;

  @include at-media("desktop") {
    float: none;
    height: units($height-nav-secondary);
    z-index: z-index($z-nav-secondary);
  }

  &__inner {
    @include at-media("desktop") {
      max-width: units("widescreen");
      margin-left: auto;
      margin-right: auto;
    }
  }

  // Search input gets cutoff on mobile.
  .usagov-search-autocomplete {
    min-width: 0;
  }

  .usa-accordion {
    @include typeset("lang");
  }

  .usa-nav__close {
    background-color: color("base-lightest");
  }

  .usa-nav__primary button {
    line-height: lh($theme-navigation-font-family, 1);
    padding: units(2);
  }

  .usa-nav__submenu-item {
    @include typeset($theme-navigation-font-family, "2xs", 2);
  }
}

.usa-nav__primary--mobile {
  @include at-media("desktop") {
    display: none;
  }

  .usa-button {
    margin-top: units(1);
  }
}

.usa-nav__primary--desktop {
  @include unstyled-list;
  display: none;

  @include at-media("desktop") {
    display: flex;
  }

  a {
    @include typeset("lang", "xs", 1);
    color: color("gray-50");
    display: inline-block;
    font-weight: fw("normal");
    height: units($height-nav-secondary);
    padding: units(2);
    position: relative;
    text-decoration: none;

    .page-home & {
      color: color("ink");
    }

    &:hover {
      @include add-bar(0.5, $site-primary-vivid, "bottom", 0, 2, 0);
      color: color($site-primary-vivid);
    }
  }

  .usa-current {
    @include add-bar(0.5, $site-primary-vivid, "bottom", 0, 2, 0);
    color: color($site-ink);
    font-weight: fw("bold");

    &:hover {
      color: color($site-ink);
    }
  }
}

.site-search {
  margin-bottom: units(1);

  @include at-media("desktop") {
    @include u-margin-y(0, "!important");
    margin-right: units(2);
    width: auto !important;
  }

  .usa-input {
    @include at-media-max("desktop") {
      height: auto;
      margin-top: 0;
      padding: 0;
    }

    @include at-media("desktop") {
      background-color: color("blue-warm-90");
      color: color("white");
      height: $site-secondary-nav-input-height;
      margin-top: 0;
    }
  }

  .usa-button {
    @include at-media("desktop") {
      background-color: color("blue-warm-50v");
      height: $site-secondary-nav-input-height;

      &:hover,
      &:active {
        background-color: color("blue-warm-60v");
      }
    }
  }
}
