@use "uswds-core" as *;
@use "usa-in-page-navigation" as *;


@mixin generate-in-page-nav-nesting($list-selector,$left-padding-width, $depth, $i: 1){
  @if $i <= $depth {
    ul.#{$list-selector} {
      .usa-in-page-nav__item a {
        padding-left: #{$left-padding-width * $i}rem;
      }
      @include generate-in-page-nav-nesting($list-selector,$left-padding-width, $depth, $i + 1);
    }
  }
}

.usa-in-page-nav-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;

  main {
    @include u-minw(15);
  }

  .site-in-page-nav {
    @extend .usa-in-page-nav;
    max-height: 95vh;
    overflow-y: hidden;
    margin-left: 0;

    @include at-media("widescreen") {
      display: block;
      order: 2;
      width: 100%;
      margin-top: 0;
      flex-shrink: 0;
      flex-grow: 1;
    }

    &__link {
      @extend .usa-in-page-nav__link !optional;

      &.h3 {
        margin-left: units(1);
      }

      &.h4 {
        margin-left: units(2);
      }

      &.h5 {
        margin-left: units(3);
      }

      &.h6 {
        margin-left: units(4);
      }
    }
  }

  .usa-in-page-nav {
    &__heading {
      margin-top: units(2);
    }

    &__nav {
      padding: 0;
    }

    &__item {
      code {
        @include typeset('mono', "2xs", 3);
      }
    }
    &__list {
      /* revert background left border */
      a:not(.usa-button) {
        &:not(.usa-current) {
          color: inherit;
          &:visited {
            color: inherit;
          }
        }
        &.usa-current {
          color: #005ea2;
          font-weight: bold;
          &::after {
            background-color: inherit;
          }
        }
      }

      &.usa-current {
        &::after {
          background-color: inherit;
        }
      }
    }
  }
}