
@media print {
  body {
    overflow-x: visible;
  }

  article, details, figcaption, figure, hgroup, main, section, summary {
    display: inline !important;
  }

  article > header {
    display: inline !important;
  }

  .grid-row {
    display: inline !important;
  }

  .usa-navbar {
    .usa-logo {
      max-width: none;
    }

    button.usa-menu-btn {
      display: none;
    }
    .usa-nav {
      display: none !important;
    }
  }

  .usa-layout-docs__sidenav {
    display: none;
  }

  .usa-layout-docs__sidenav {
    display: none;
  }

  .usa-hero {
    background-image: none;
  }

  .usa-hero__callout {
    background-color: transparent;
    p {
      color: color('gray-70');
    }
  }
  .usa-hero__heading {
    color: color('gray-60');
  }
  .usa-hero__heading--alt {
    color: color('black');
  }

  .usa-section--light {
    background-color: transparent;
  }

  .usa-section--dark {
    background-color: transparent;
    color: color('black');

    .usa-media-block__body {
      .usa-graphic-list__heading {
        color: color('black');
      }
      p {
        color: color('gray-70');
      }
    }
  }

  .usa-breadcrumb, .usa-banner {
    display: none !important;
  }

  code {
    box-shadow: none;
  }
}
